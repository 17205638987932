import propTypes from 'prop-types';
import React, { useState } from 'react';
import { BASE_URL } from '../../../../globals';
import RoomPreview from './RoomPreview';

const BookingLinkProductRoom = ({ product, media, bookingData }) => {
  const productRooms = bookingData[product];
  const [selectedRoomType, setSelectedRoomType] = useState(productRooms[0]);

  const roomTypes = Object.values(
    productRooms.reduce((acc, roomType) => {
      const code = roomType.category.code;
      if (!acc[code]) {
        acc[code] = [];
      }
      acc[code].push(roomType);
      return acc;
    }, {})
  );

  const imagesForSelectedRoomType = media.filter((mediaItem) =>
    mediaItem.includes(`RoomImages/${product}/${selectedRoomType.code}`)
  );

  return (
    <div key={product}>
      <div className="text-center">
        <h1>VILLA MARGHERITA</h1>
        <div style={{ fontWeight: 300, paddingBottom: '40px' }}>
          Tucked away amongst the Caruso gardens, Villa Margherita features an
          iconic rooftop terrace with dream like 360° views, two living rooms
          and four bathrooms. A private butler takes care of your needs, and a
          dedicated chef caters for you - exactly the way you want.
        </div>
      </div>
      <div
        style={{
          minHeight: '360px',
          marginTop: '50px',
          marginBottom: '70px',
        }}
      >
        <RoomPreview
          title={selectedRoomType.title}
          categoryName={selectedRoomType.category.name}
          images={imagesForSelectedRoomType}
          bedding={selectedRoomType.bedding}
          size={selectedRoomType.size}
          sleeps={selectedRoomType.sleeps}
          longDescription={selectedRoomType.longDescription}
          onButtonClick={() => {
            window.location.href = `${BASE_URL}/select-room?productCode=${product}`;
          }}
          roomTypes={roomTypes[0]}
          onChangeSelectedRoomType={setSelectedRoomType}
          selectedRoomTypeCode={selectedRoomType.code}
        />
      </div>
    </div>
  );
};

BookingLinkProductRoom.propTypes = {
  product: propTypes.string,
  media: propTypes.array,
  bookingData: propTypes.object,
};

export default BookingLinkProductRoom;
