import PropTypes from 'prop-types';

import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { ReactComponent as GreenCheckIcon } from '../../../../assets/images/icons/greenCheck.svg';
import BookingButton from '../../../../components/BookingButton/BookingButton';
import usePrintPrice from '../../../../hooks/usePrintPrice/usePrintPrice';
import useRateContent from '../../../../hooks/useRateContent/useRateContent';
import { useScreenDetector } from '../../../../hooks/useScreenDetector/useScreenDetector';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from './RoomRate.module.css';
import { DesktopLayout, MobileLayout } from './components/layout';

const RoomRate = ({
  rate,
  selectRoomForm,
  productCode,
  roomTypeCode,
  roomTypeTitle,
  numBookingNights,
  onRateDetailsBtnClick,
  onBookNowBtnClick,
  renderSecondaryButton,
  highlightedRoomIndex = 0,
}) => {
  const { t } = useTranslate();
  const { code, name, shortDescription, currencyCode, pricesPerNight } = rate;
  const { isMobile } = useScreenDetector();
  const { printPrice } = usePrintPrice(productCode);
  const formValues = selectRoomForm.watch();

  const { numAdults, children } =
    selectRoomForm?.watch('guestsPerRoom')?.[highlightedRoomIndex] || [];

  const { rateContent, loading, agentCommission } = useRateContent(
    code,
    productCode,
    { ...formValues, numAdults, children }
  );

  const { discretionaryCharge } = useSelector(
    (state) => state.belmond.hotels[productCode]
  );

  const numRooms = selectRoomForm?.watch('guestsPerRoom')?.length || 1;
  const agentCrmId = selectRoomForm?.watch('agentCrmId');
  const agentId = selectRoomForm?.watch('agentId');

  const { price: ratePrice, isCaliforniaTax } = printPrice(
    {
      value: pricesPerNight.reduce(
        (acc, { price, tax }) => acc + price + tax,
        0
      ),
      valueExcludingTaxesIncludingServiceCharge: pricesPerNight.reduce(
        (acc, { price, serviceCharge }) => acc + price + serviceCharge,
        0
      ),
      productCode,
      baseCurrency: currencyCode,
    },
    true
  );

  let priceTypeMessage = '';

  if (isCaliforniaTax) {
    priceTypeMessage = [
      `${t('Excluding')} ${t('Taxes')}`,
      `${t('Including')} ${t('Service Charge')}`,
    ];
  } else if (discretionaryCharge) {
    priceTypeMessage = [
      `${t('Including')} ${t('Taxes')}`,
      t('Excluding 5% Service Charge'),
    ];
  } else {
    priceTypeMessage = [`${t('Including')} ${t('Taxes and Fees')}`];
  }

  const commonProps = {
    name,
    code,
    loading,
    rateContent,
    shortDescription,
    onRateDetailsBtnClick,
    priceTypeMessage,
    numBookingNights,
    t,
    ratePrice,
  };

  return (
    <div className={styles.RoomRate__container}>
      {agentId && agentCrmId && agentCommission > 0 && (
        <div className={styles.RoomRate__commissionContainer}>
          <div className={styles.RoomRate__icon}>
            <GreenCheckIcon />
          </div>
          <div>
            <div className={styles.RoomRate__description}>
              <span className="bold">{t('Offer applied')}: </span>
              {`${Math.round(agentCommission)}% ${t('commission per night')}`}
            </div>
          </div>
        </div>
      )}

      {isMobile ? (
        <div className={styles.RoomRate__mobileView}>
          <MobileLayout {...commonProps} />
        </div>
      ) : (
        <div className={styles.RoomRate__desktopView}>
          <DesktopLayout {...commonProps} />
        </div>
      )}

      <div className={styles.RoomRate__buttons}>
        <div>{renderSecondaryButton}</div>
        <div>
          {loading ? (
            <Skeleton width={160} height={48} className="py-1" />
          ) : (
            <BookingButton
              rateCode={code}
              rateName={name}
              roomTypeTitle={roomTypeTitle}
              onBook={onBookNowBtnClick}
              roomTypeCode={roomTypeCode}
              numRooms={numRooms}
              gtmView="Regular"
            />
          )}
        </div>
      </div>
    </div>
  );
};

RoomRate.propTypes = {
  rate: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    longDescription: PropTypes.string,
    currencyCode: PropTypes.string.isRequired,
    pricesPerNight: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        tax: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  selectRoomForm: PropTypes.object.isRequired,
  productCode: PropTypes.string.isRequired,
  roomTypeCode: PropTypes.string.isRequired,
  numBookingNights: PropTypes.number.isRequired,
  onRateDetailsBtnClick: PropTypes.func.isRequired,
  onBookNowBtnClick: PropTypes.func.isRequired,
  renderSecondaryButton: PropTypes.node,
  roomTypeTitle: PropTypes.string.isRequired,
  highlightedRoomIndex: PropTypes.number,
};

export default RoomRate;
