import PropTypes from 'prop-types';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Col, Row } from 'reactstrap';
import { ReactComponent as CreditCardIcon } from '../../../../assets/images/icons/CreditCard.svg';
import { gtmAddPaymentInfo } from '../../../../gtm/events';
import useAxios from '../../../../hooks/useAxios/useAxios';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import './styles.css';
import { CeloPayInputField } from './CeloPayInputField';

const inputCss = {
  border: 'none',
  height: '40px',
  borderBottom: '1px solid #DBD9D9',
  paddingLeft: '10px',
  '&:active': {
    border: 'none',
    borderBottom: '1px solid #00122C',
  },
  '&:focus-visible': {
    outline: 'none',
  },
  fontSize: '16px',
  '&.valid': {
    border: 'none',
    borderBottom: '1px solid #DBD9D9',
    color: 'black',
  },
  '&.touched': {
    border: 'none',
    borderBottom: '1px solid #DBD9D9',
    color: 'black',
  },
  '&:focus': {
    border: 'none',
    borderBottom: '1px solid #00122C',
  },
  '&.error': {
    border: 'none',
    borderBottom: '1px solid #E22D2C',
    color: 'black',
  },
};

const CelopayPayment = forwardRef(
  (
    {
      onSubmit,
      onSuccess,
      onFailure,
      onMountingStart,
      onMountingEnd,
      productCode,
      onShowMessage,
      paymentId,
    },
    ref
  ) => {
    const { t } = useTranslate();
    const form = useRef();
    const paymentBrand = useRef();
    const mounted = useRef(false);

    const [session, setSession] = useState(null);
    const [errors, setErrors] = useState([]);

    const axios = useAxios();

    useImperativeHandle(ref, () => ({
      submit: () => {
        if (!form.current) {
          onShowMessage(
            t(
              'Something went wrong loading the payment form. Please refresh the page.'
            ),
            'danger'
          );
          return false;
        }
        return handleSubmit();
      },
    }));

    const handleSubmit = useCallback(async () => {
      onSubmit && (await onSubmit());
      setErrors([]);
      try {
        window.handleSuccess = () => {
          gtmAddPaymentInfo(paymentBrand.current);
          onSuccess &&
            onSuccess({ orderReference: session.id, shopperReference: null });
        };
        if (paymentId) {
          await axios.post('/celopay-payment-session-details', {
            paymentId,
            sessionId: session.id,
          });
        }

        await form.current.submit(
          '#cc-details',
          {
            locationId: session.locationId,
          },
          {},
          (errors) => {
            if (errors) {
              setErrors(errors);
              onFailure && onFailure();
            }
          }
        );
      } catch (error) {
        onShowMessage(
          error?.message ||
            t('Failure authorizing your payment. Please try again.'),
          'danger'
        );
        onFailure && onFailure();
      }
    }, [
      onFailure,
      onSubmit,
      onSuccess,
      session,
      onShowMessage,
      t,
      paymentId,
      axios,
    ]);

    const createCheckout = useCallback(
      (session) => {
        if (form.current) {
          document.getElementsByClassName('celopay-field').forEach((field) => {
            field.innerHTML = '';
          });
        }
        // eslint-disable-next-line no-undef
        form.current = CLPCForm.create(
          '#cc-details',
          session.clientId,
          session.instanceId,
          'sandbox',
          (data) => {
            if (data['cc-number']?.cardType) {
              paymentBrand.current = data['cc-number'].cardType;
            }
          },
          session.siteCode
        );
        form.current.field('#cartId', {
          name: 'cartId',
          type: 'hidden',
          value: session.id,
        });
        form.current.field('#sequenceNo', {
          name: 'sequenceNo',
          type: 'hidden',
          value: 'ignore',
        });
        form.current.field('#cc-number', {
          name: 'cc-number',
          type: 'card-number',
          value: '',
          css: inputCss,
          successColor: '#3c763d',
          errorColor: '#a94442',
          validations: ['required', 'card-number'],
          autoComplete: 'cc-number',
          showCardIcon: {
            right: '5px',
            width: '37px',
            height: '25px',
          },
        });
        form.current.field('#expire-date', {
          type: 'expire-date',
          value: '',
          name: 'expire-date',
          css: inputCss,
          autoComplete: 'off',
          yearLength: 2,
          validations: ['required', 'expire-date'],
          serializers: [
            { name: 'separate', monthName: 'month', yearName: 'year' },
          ],
        });
        form.current.field('#cvc', {
          type: 'security-code',
          name: 'cvc',
          value: '',
          css: inputCss,
          autoComplete: 'off',
          showCardIcon: true,
          hideValue: false,
          validations: ['required', 'security-code'],
        });
        form.current.field('#card-name', {
          name: 'card-name',
          value: '',
          type: 'text',
          css: inputCss,
          validations: ['required'],
        });
        form.current.field('#billingPostalCode', {
          name: 'billing-postalCode',
          type: 'hidden',
          value: 'ignore',
        });
        onMountingEnd();
      },
      [onMountingEnd]
    );

    const initSession = useCallback(async () => {
      try {
        const response = await axios.post('/celopay-sessions', { productCode });
        const { sessionId, clientId, instanceId, locationId, siteCode } =
          response.data;
        const sessionObj = {
          id: sessionId,
          clientId,
          instanceId,
          locationId,
          siteCode,
        };
        setSession(sessionObj);
        createCheckout(sessionObj);
      } catch (e) {
        onShowMessage(
          'Error initializing payment form - please refresh the page.',
          'danger'
        );
      }
    }, [axios, productCode, createCheckout, onShowMessage]);

    useEffect(() => {
      if (!mounted.current) {
        mounted.current = true;
        onMountingStart();
        if (!document.getElementById('celopay-script')) {
          const script = document.createElement('script');
          script.id = 'celopay-script';
          script.src =
            'https://development-capture.celopay.com/js/TH/CeloPayCapture.min.js';
          script.async = true;
          script.onload = () => {
            initSession();
          };

          document.body.appendChild(script);
        } else {
          initSession();
        }
      }
    }, [initSession, onMountingStart]);

    useEffect(() => {
      const interval = setInterval(() => initSession(), 23 * 60 * 60 * 1000);
      return () => clearInterval(interval);
    }, [createCheckout, initSession]);

    return (
      <div className="w-100 h-100">
        <div className={`celoPay w-100 h-100`}>
          <div className="mb-2">
            <div className="paymentContainerHeading">
              <CreditCardIcon className="creditCardIcon" />
              Credit or Debit Card
            </div>
            <div className="headingParagraph">
              All fields are required unless marked otherwise.
            </div>
          </div>
          <form id="cc-details" style={{ width: '100%' }}>
            <div>
              <div id="cartId" style={{ display: 'none' }} />
              <div id="sequenceNo" style={{ display: 'none' }} />
              <CeloPayInputField
                fieldName="cc-number"
                label="Card number"
                errors={errors}
              />
              <Row className="middleRowFields">
                <Col>
                  <CeloPayInputField
                    fieldName="expire-date"
                    label="Expiry date"
                    errors={errors}
                    inputDescription="Front of card in MM/YY format"
                  />
                </Col>
                <Col>
                  <CeloPayInputField
                    fieldName="cvc"
                    label="CVC"
                    errors={errors}
                    inputDescription="3 digits on back of the card
"
                  />
                </Col>
              </Row>
              <CeloPayInputField
                fieldName="card-name"
                label="Cardholder name"
                errors={errors}
              />
              <div id="billingPostalCode" style={{ display: 'none' }} />
            </div>
            <div id="result-message" style={{ display: 'none' }} />
            <div id="error-message" style={{ display: 'none' }} />
          </form>
        </div>
      </div>
    );
  }
);

CelopayPayment.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  metadata: PropTypes.object,
  onMountingStart: PropTypes.func,
  onMountingEnd: PropTypes.func,
  productCode: PropTypes.string,
  countryCode: PropTypes.string,
  onShowMessage: PropTypes.func,
  onCloseMessage: PropTypes.func,
  paymentId: PropTypes.string,
};

export default CelopayPayment;
