import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import RoomRatesListItem from '../RoomRatesListItem/RoomRatesListItem';

const RoomRatesList = ({
  roomTypesResults,
  productCode,
  numBookingNights,
  onBook,
  selectRoomForm,
  highlightedRoomIndex = 0,
}) => {
  const roomsTypesByRate = useMemo(
    () =>
      Object.entries(
        roomTypesResults.reduce((acc, roomType) => {
          roomType.rates.forEach((rate) => {
            if (!acc[rate.code]) {
              acc[rate.code] = [];
            }
            acc[rate.code].push({
              ...roomType,
              rates: [rate],
            });
          });
          return acc;
        }, {})
      ),
    [roomTypesResults]
  );

  return roomsTypesByRate.map(([rateCode, roomTypes], i) => (
    <div key={rateCode}>
      <RoomRatesListItem
        selectRoomForm={selectRoomForm}
        key={rateCode}
        roomTypes={roomTypes}
        onBook={onBook}
        numBookingNights={numBookingNights}
        productCode={productCode}
        highlightedRoomIndex={highlightedRoomIndex}
      />
    </div>
  ));
};

RoomRatesList.propTypes = {
  roomTypesResults: PropTypes.array,
  numBookingNights: PropTypes.number,
  onBook: PropTypes.func,
  productCode: PropTypes.string,
  selectRoomForm: PropTypes.object,
  highlightedRoomIndex: PropTypes.number,
};

export default RoomRatesList;
