import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/icons/ArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/images/icons/ArrowUp.svg';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import RoomCategoriesListItem from '../RoomCategoriesListItem/RoomCategoriesListItem';
import styles from './RoomRatesListItem.module.css';

const RoomRatesListItem = ({
  roomTypes,
  productCode,
  onBook,
  numBookingNights,
  selectRoomForm,
  highlightedRoomIndex = 0,
}) => {
  const { t } = useTranslate();
  const [roomsCollapsed, setRoomsCollapsed] = useState(true);

  const roomsTypesByCategory = useMemo(
    () =>
      Object.values(
        roomTypes.reduce((acc, roomType) => {
          const code = roomType.category.code;
          if (!acc[code]) {
            acc[code] = [];
          }
          acc[code].push(roomType);
          return acc;
        }, {})
      ),
    [roomTypes]
  );

  useEffect(() => {
    // this is because if for some reason (like when filtering) the rates length changes to 1, we need to collapse the rates
    if (roomsTypesByCategory.length === 1 && !roomsCollapsed) {
      setRoomsCollapsed(true);
    }
  }, [roomsTypesByCategory.length, roomsCollapsed]);

  return (
    <div data-testid={`${roomTypes[0].rates[0].code}-group`}>
      <div className={styles.RoomRatesListItem__container}>
        <div className={styles.RoomRatesListItem__heading}>
          {roomTypes[0].rates[0].name}
        </div>
        <Row className="d-flex justify-content-between">
          <Col md="6">{parse(roomTypes[0].rates[0].shortDescription)}</Col>
        </Row>
        <hr className={styles.RoomRatesListItem__divider} />
      </div>

      {roomsCollapsed && (
        <RoomCategoriesListItem
          roomTypes={roomsTypesByCategory[0]}
          onBook={onBook}
          productCode={productCode}
          numBookingNights={numBookingNights}
          selectRoomForm={selectRoomForm}
          highlightedRoomIndex={highlightedRoomIndex}
          renderSecondaryButton={
            roomsTypesByCategory.length > 1 && (
              <Button
                className="button transparent"
                onClick={() => setRoomsCollapsed(false)}
                aria-label="View more rooms"
              >
                {t('More Rooms')}{' '}
                <ArrowDownIcon style={{ marginLeft: '4px' }} />
              </Button>
            )
          }
        />
      )}
      {roomsTypesByCategory.length > 1 && !roomsCollapsed && (
        <>
          <div>
            {roomsTypesByCategory.map((categoryRoomTypes) => (
              <RoomCategoriesListItem
                selectRoomForm={selectRoomForm}
                key={categoryRoomTypes[0].category.code}
                roomTypes={categoryRoomTypes}
                onBook={onBook}
                productCode={productCode}
                highlightedRoomIndex={highlightedRoomIndex}
                numBookingNights={numBookingNights}
              />
            ))}
          </div>
          <div className={styles.RoomRatesListItem__hideButtonContainer}>
            <Button
              className="button transparent"
              onClick={() => setRoomsCollapsed(true)}
              aria-label="Less rooms"
            >
              {t('Less Rooms')}
              <ArrowUpIcon style={{ marginLeft: '6px', marginBottom: '2px' }} />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
RoomRatesListItem.propTypes = {
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.array,
      rates: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          shortDescription: PropTypes.string.isRequired,
        })
      ).isRequired,
      category: PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  productCode: PropTypes.string.isRequired,
  onBook: PropTypes.func.isRequired,
  numBookingNights: PropTypes.number.isRequired,
  selectRoomForm: PropTypes.object,
  highlightedRoomIndex: PropTypes.number,
};
export default RoomRatesListItem;
