import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

export const CeloPayInputField = ({
  fieldName,
  label,
  errors,
  inputDescription,
}) => {
  const errorMessages = errors?.find((error) => error.name === fieldName)
    ?.errorMessage?.[0];

  const formattedErrorMessage = errorMessages
    ? [label, ...errorMessages.split(' ').slice(1)].join(' ')
    : null;

  return (
    <div className="d-flex flex-column">
      <Label style={{ color: errorMessages?.length ? '#E22D2C' : 'black' }}>
        {label}
      </Label>
      <div id={fieldName} />
      {errorMessages?.length ? (
        <div style={{ color: '#E22D2C', fontSize: '12px' }}>
          {formattedErrorMessage}
        </div>
      ) : (
        inputDescription
      )}
    </div>
  );
};

CeloPayInputField.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.array,
  inputDescription: PropTypes.string,
};
