import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import SkeletonLoading from '../../../../components/SkeletonLoading/SkeletonLoading';
import RoomCategoriesListItem from '../RoomCategoriesListItem/RoomCategoriesListItem';

const RoomCategoriesList = ({
  roomTypesResults,
  productCode,
  numBookingNights,
  onBook,
  loading,
  selectRoomForm,
  highlightedRoomIndex = 0,
}) => {
  const roomsTypesByCategory = useMemo(
    () =>
      Object.values(
        roomTypesResults.reduce((acc, roomType) => {
          const code = roomType.category.code;
          if (!acc[code]) {
            acc[code] = [];
          }
          acc[code].push(roomType);
          return acc;
        }, {})
      ),
    [roomTypesResults]
  );

  return (
    <div className="py-3">
      {loading
        ? [...Array(10).keys()].map((key) => <SkeletonLoading key={key} />)
        : roomsTypesByCategory.map((roomTypes) => (
            <RoomCategoriesListItem
              highlightedRoomIndex={highlightedRoomIndex}
              selectRoomForm={selectRoomForm}
              key={`${roomTypes[0].category.code}-${roomTypes.length}`}
              onBook={onBook}
              productCode={productCode}
              roomTypes={roomTypes}
              numBookingNights={numBookingNights}
            />
          ))}
    </div>
  );
};

RoomCategoriesList.propTypes = {
  numBookingNights: PropTypes.number,
  onBook: PropTypes.func,
  productCode: PropTypes.string,
  roomTypesResults: PropTypes.array,
  loading: PropTypes.bool,
  selectRoomForm: PropTypes.object,
  highlightedRoomIndex: PropTypes.number,
};

export default memo(RoomCategoriesList);
