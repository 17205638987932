import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import RoomImage from '../../../../components/RoomImage/RoomImage';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import styles from '../RoomType/RoomType.module.css';
import RoomTypeSelector from '../RoomType/components/RoomTypeSelector/RoomTypeSelector';
import { splitDescriptionIntoLists } from '../RoomType/helpers';

const RoomPreview = ({
  title,
  categoryName,
  sleeps,
  images,
  bedding,
  size,
  longDescription,
  onButtonClick,
  roomTypes,
  onChangeSelectedRoomType,
  selectedRoomTypeCode,
}) => {
  const { t } = useTranslate();
  const formattedCategoryName =
    roomTypes?.length > 1 || !categoryName?.toLowerCase().endsWith('s')
      ? categoryName
      : categoryName.slice(0, -1);

  const { firstList, secondList } = splitDescriptionIntoLists(longDescription);

  return (
    <Row className="position-relative mt-4">
      <Col md="5">
        <div className={styles.Room__slider}>
          <RoomImage mainImage={images[0]} title={title} galleryDisabled />
        </div>
      </Col>
      <Col
        md="7"
        className={styles.Room__room}
        style={{
          minHeight: '350px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div className={styles.Room_info}>
          <div className={styles.Room__title}>{formattedCategoryName}</div>
          <RoomTypeSelector
            roomTypes={roomTypes}
            selectedRoomTypeCode={selectedRoomTypeCode}
            onChange={onChangeSelectedRoomType}
          />
          <div className={styles.Room__roomDetails}>
            <span>
              {t('Sleeps')} {sleeps}
            </span>
            <span>{bedding}</span>
            <span>
              {size?.value}
              {size?.units}
            </span>
          </div>
          {longDescription.includes('<ul>') ? (
            <div className={styles.Room__listGridContainer}>
              <ul className={styles.Room__listGridColumn}>
                {parse(firstList.join(''))}
              </ul>
              <ul className={styles.Room__listGridColumn}>
                {parse(secondList.join(''))}
              </ul>
            </div>
          ) : (
            parse(longDescription)
          )}
        </div>
        <hr className="bbe-hr" style={{ margin: '20px 28px' }} />
        <div className="d-flex w-100 justify-content-end">
          <Button
            className="button"
            onClick={onButtonClick}
            style={{ margin: '0 28px' }}
          >
            Learn More
          </Button>
        </div>
      </Col>
    </Row>
  );
};

RoomPreview.propTypes = {
  title: PropTypes.string.isRequired,
  sleeps: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  video: PropTypes.string,
  bedding: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  longDescription: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  roomTypes: PropTypes.array.isRequired,
  onChangeSelectedRoomType: PropTypes.func.isRequired,
  selectedRoomTypeCode: PropTypes.string.isRequired,
};

export default RoomPreview;
