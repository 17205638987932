import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from '../useAxios/useAxios';
import useLocalisedMoment from '../useLocalisedMoment/useLocalisedMoment';
import {
  createFetchParams,
  getCurrentContent,
  shouldFetchContent,
  shouldFetchNewContent,
} from './helpers';
import performFetch from './helpers/perform-fetch';

const useRateContent = (
  rateCode,
  productCode,
  { startDate, endDate, numAdults = 2, children = [], agentId, id: bookingId }
) => {
  const dispatch = useDispatch();
  const moment = useLocalisedMoment();
  const axios = useAxios();
  const [rateContent, setRateContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const currentLanguage = useSelector(
    (state) => state.appSettings.languages.current
  );

  const rateContentData = useSelector(
    (state) => state.belmond.hotelsRateContent
  );

  const bookingRateContentData = useSelector(
    (state) => state.belmond.bookingRateContent
  );

  const highestAgentCommissionEnabled = useSelector(
    (state) => state.appSettings.featureFlags.highestAgentCommissionEnabled
  );

  const agentCommission = useGetAgentCommission(
    highestAgentCommissionEnabled,
    rateContent,
    agentId
  );

  // Helper function to generate the localStorage key
  const getStorageKey = (productCode, identifier, source) => {
    const sourceKey =
      source === 'booking' ? `booking-${identifier}` : identifier;
    return `fetching-${productCode}-${sourceKey}`;
  };

  // remove the item once the component is unmounted
  useEffect(() => {
    const storageKey = getStorageKey(
      productCode,
      rateCode,
      bookingId ? 'booking' : 'rate'
    );
    return () => {
      localStorage.removeItem(storageKey);
    };
  }, [productCode, rateCode, bookingId]);

  useEffect(() => {
    let isMounted = true;

    const fetchContent = async () => {
      if (
        !shouldFetchContent(startDate, rateCode, productCode, endDate, moment)
      ) {
        if (isMounted) setLoading(false);
        return;
      }

      const params = createFetchParams({
        rateCode,
        startDate,
        endDate,
        numAdults,
        children,
        productCode,
        agentId,
        currentLanguage,
      });

      const storageKey = getStorageKey(
        productCode,
        bookingId || rateCode,
        bookingId ? 'booking' : 'rate'
      );

      const currentContent = getCurrentContent(
        bookingId,
        productCode,
        rateCode,
        bookingRateContentData,
        rateContentData
      );

      if (currentContent && isMounted) {
        setRateContent(currentContent);
        setLoading(false);
      }

      const shouldFetch = shouldFetchNewContent(
        currentContent,
        params,
        storageKey,
        agentId,
        moment
      );

      if (shouldFetch) {
        await performFetch({
          isMounted,
          setLoading,
          storageKey,
          params,
          moment,
          axios,
          bookingId,
          dispatch,
          setError,
        });
      } else if (!localStorage.getItem(storageKey) && isMounted) {
        setLoading(false);
      }
    };

    fetchContent();

    return () => {
      isMounted = false;
    };
  }, [
    dispatch,
    numAdults,
    children,
    productCode,
    rateCode,
    rateContentData,
    bookingRateContentData,
    moment,
    axios,
    currentLanguage,
    agentId,
    startDate,
    endDate,
    bookingId,
  ]);

  return { rateContent, loading, error, agentCommission };
};

// helper hook
const useGetAgentCommission = (
  highestAgentCommissionEnabled,
  rateContent,
  agentId
) => {
  return (
    useSelector((state) => {
      const rateAgentCommission = rateContent?.commissionablePercentage;
      if (highestAgentCommissionEnabled) {
        return state.belmond.agents[agentId]?.commission;
      }
      return rateAgentCommission;
    }) || 0
  );
};

export default useRateContent;
