import Skeleton from 'react-loading-skeleton';
import { Button } from 'reactstrap';
import { RoomRateDetails } from '../';
import styles from '../../RoomRate.module.css';
import layoutProps from './constants/props';

const MobileLayout = ({
  name,
  code,
  loading,
  rateContent,
  shortDescription,
  onRateDetailsBtnClick,
  priceTypeMessage,
  numBookingNights,
  t,
  ratePrice,
}) => (
  <div className={styles.RoomRate__mobileContainer}>
    <div className={styles.RoomRate__mobileHeader}>
      <div className="fs-5 fw-600">{name}</div>
      <div className={styles.RoomRate__price} data-testid="RoomRate--price">
        {ratePrice}
      </div>
    </div>

    {loading ? (
      <div>
        <Skeleton className="my-3" height={25} />
        <Skeleton className="mb-3" height={50} />
      </div>
    ) : (
      <>
        <div className={styles.RoomRate__mobileDetails}>
          <RoomRateDetails
            name={name}
            rateContent={rateContent}
            shortDescription={shortDescription}
          />
        </div>
        <div className={styles.RoomRate__mobileActions}>
          <div className={styles.RoomRate__mobileNightInfo}>
            <div>
              {t('Total for')} {numBookingNights}{' '}
              {numBookingNights > 1 ? t('nights') : t('night')}
            </div>
            <div className="me-3">
              {priceTypeMessage.map((m) => (
                <div key={m}>{m}</div>
              ))}
            </div>
          </div>
          <Button
            className="hoverEffectButton text-nowrap"
            onClick={onRateDetailsBtnClick}
            aria-label={`Open modal for ${name}`}
            id={`${name}-${code}`}
          >
            {t('Rate Details')}
          </Button>
        </div>
      </>
    )}
  </div>
);

MobileLayout.propTypes = layoutProps;

export default MobileLayout;
