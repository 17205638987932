import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import DevLogin from './components/DevLogin/DevLogin';
import { currencies as hardcodedCurrencies } from './constants';
import { CHINA_VERSION, env } from './globals';
import ScriptLoader from './helpers/ScriptLoader/ScriptLoader';
import useOnetrustActiveGroups from './helpers/ScriptLoader/hooks/useOneTrustActiveGroups';
import devLoginCheck from './helpers/devLoginCheck';
import getCountryCode from './helpers/get-country-code';
import useAxios from './hooks/useAxios/useAxios';
import useCheckReach5Session from './hooks/useCheckReach5Session/useCheckReach5Session';
import {
  fetchExchanges,
  fetchLanguages,
  fetchStaticCnTranslations,
  setAdditionalPolicy,
  setCountryCode,
  setCurrencies,
  setHighestAgentCommissionEnabled,
  setloginEnabled,
  setNewsletterEnabled,
} from './redux/slices/appSettingsSlice/appSettingsSlice';
import {
  fetchHotels,
  setPhone,
} from './redux/slices/belmondSlice/belmondSlice';
import { fetchMedia } from './redux/slices/mediaSlice/mediaSlice';
import { checkActiveAuthSession } from './redux/slices/userSlice/userSlice';

const isAuthCheckSkip = (param) => {
  if (param === 'false') {
    localStorage.removeItem('skipAuthCheck');
    return false;
  }

  let skip = localStorage.getItem('skipAuthCheck');
  if (skip === 'true') return true;

  if (param === 'true') {
    localStorage.setItem('skipAuthCheck', 'true');
    return true;
  }

  return false;
};

const Root = () => {
  useCheckReach5Session();
  const axios = useAxios();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const consents = useOnetrustActiveGroups();

  const [initialCallsMade, setInitialCallsMade] = useState(false);
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const countryCode = useSelector((state) => state.appSettings.countryCode);
  const bookings = useSelector((state) => state.bookings.list);

  const bypassParam = searchParams.get('bypass');
  const skipAuthCheckParam = searchParams.get('skipAuthCheck');
  const productCode = searchParams.get('productCode');

  const isDevLoginValid =
    JSON.parse(localStorage.getItem('validUser'))?.valid ||
    env === 'prod' ||
    env === 'local' ||
    bypassParam === 'diamond';

  const fetchCountryCode = useCallback(async () => {
    if (CHINA_VERSION) {
      dispatch(setCountryCode('CN'));
    } else {
      const result = await getCountryCode();
      dispatch(setCountryCode(result));
    }
  }, [dispatch]);

  const fetchMetadata = useCallback(async () => {
    if (!countryCode) return;

    try {
      const res = await axios.get('/metadata', {
        params: { country_code: countryCode },
      });

      dispatch(setPhone(res.data.phone));
      dispatch(setloginEnabled(res.data.loginEnabled));
      dispatch(setNewsletterEnabled(res.data.newsletterEnabled));
      dispatch(
        setHighestAgentCommissionEnabled(res.data.highestAgentCommissionEnabled)
      );
      dispatch(setAdditionalPolicy(res.data.bbeAdditionalPolicy));
    } catch (e) {
      console.error(e?.message || e);
    }
  }, [axios, dispatch, countryCode]);

  useEffect(() => {
    if (bypassParam === 'diamond') {
      window.localStorage.setItem(
        'validUser',
        JSON.stringify({
          valid: true,
          timestamp: Date.now(),
        })
      );
    }
  }, [bypassParam]);

  useEffect(() => {
    const initCalls = async () => {
      // axios changes based on pathname
      // so we need a state to track initial calls to avoid
      // to avoid multiple calls
      if (initialCallsMade) return;

      try {
        await fetchCountryCode();
      } catch (e) {
        // ignore - falls back to GB inside getCountryCode
      } finally {
        const promises = [
          fetchMetadata(),
          dispatch(fetchExchanges(axios)),
          dispatch(fetchLanguages(axios)),
          dispatch(fetchHotels(axios, countryCode)),
          dispatch(fetchMedia(axios)),
        ];

        if (CHINA_VERSION) {
          promises.push(dispatch(fetchStaticCnTranslations(axios)));
        }

        await Promise.all(promises);

        setInitialCallsMade(true);
        dispatch(setCurrencies(hardcodedCurrencies));
        setLoading(false);
        devLoginCheck();
      }
    };

    if (scriptsLoaded) {
      initCalls();
    }
  }, [
    fetchMetadata,
    dispatch,
    scriptsLoaded,
    axios,
    fetchCountryCode,
    countryCode,
    productCode,
    initialCallsMade,
  ]);

  useEffect(() => {
    const skipAuthCheck = isAuthCheckSkip(skipAuthCheckParam);
    if (skipAuthCheck) return;
    dispatch(checkActiveAuthSession());
  }, [dispatch, skipAuthCheckParam]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [location]);

  useEffect(() => {
    const isEnterprisePage = location.pathname === '/';
    if (isEnterprisePage && bookings.length) {
      navigate('/checkout', { replace: true });
    }
  }, [location, navigate, bookings]);

  useEffect(() => {
    const isDY = consents.includes('C0003');
    if (window.DYO?.ActiveConsent?.updateConsentAcceptedStatus) {
      window.DYO.ActiveConsent.updateConsentAcceptedStatus(isDY);
    }
  }, [consents]);

  return (
    <>
      <ScriptLoader onScriptsLoad={() => setScriptsLoaded(true)} />
      {isDevLoginValid ? (
        <Outlet context={{ scriptsLoaded, metadataLoaded: !loading }} />
      ) : (
        <DevLogin />
      )}
    </>
  );
};

export default Root;
