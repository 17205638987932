import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import useTranslate from '../../hooks/useTranslate/useTranslate';
import styles from './Policies.module.css';

const camelToTitle = (camelCase) => {
  const result = camelCase.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const Policies = ({
  policies = {},
  loading,
  showCommission,
  additionalPolicyText,
}) => {
  const { t } = useTranslate();
  let activePolicies = policies;

  if (loading)
    return (
      <>
        <Skeleton height={20} width={100} containerTestId="skeleton" />
        <Skeleton height={20} width="100%" />

        <Skeleton height={20} width={100} className="mt-4" />
        <Skeleton height={20} width="100%" />
      </>
    );

  if (!showCommission) {
    activePolicies = Object.fromEntries(
      Object.entries(policies).filter(
        ([type, _]) => type !== 'commissionPolicy'
      )
    );
  }

  if (additionalPolicyText) {
    const additionalPolicy = {
      additionalPolicy: [{ description: additionalPolicyText }],
    };
    activePolicies = { ...activePolicies, ...additionalPolicy };
  }

  return (
    <>
      {Object.keys(policies).length === 0 ? (
        <div>{t('No policies could be loaded.')}</div>
      ) : (
        Object.entries(activePolicies)
          .filter(([_, items]) => items.length)
          .map(([type, items]) => (
            <div key={type} className={styles.policy}>
              <h6>{t(camelToTitle(type))}</h6>
              {items.map(({ description }, i) => (
                <span key={`${type}-policy-${i}`}>{description}</span>
              ))}
            </div>
          ))
      )}
    </>
  );
};

Policies.propTypes = {
  policies: PropTypes.object,
  loading: PropTypes.bool,
  showCommission: PropTypes.bool,
  additionalPolicyText: PropTypes.string,
};

export default Policies;
