import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CURRENCY_SYMBOLS } from '../../../../../../constants';
import useTranslate from '../../../../../../hooks/useTranslate/useTranslate';
import CalendarFeedback from '../CalendarFeedback/CalendarFeedback';
import styles from './CalendarLegend.module.css';

const CalendarLegend = ({
  disabledPickedDateReason,
  isSpecialCode,
  productCode,
}) => {
  const { t } = useTranslate();
  const currencyCode = useSelector(
    (state) => state.appSettings.currencies.current
  );

  const { discretionaryCharge } = useSelector(
    (state) => state.belmond.hotels[productCode]
  );

  const { getValues } = useFormContext();

  const multiRooms = getValues('guestsPerRoom').length > 1;
  const currencyData = CURRENCY_SYMBOLS[currencyCode];

  return (
    <div>
      <div className={styles.CalendarLegend__container}>
        <div className="d-flex align-items-center">
          <div className={styles.CalendarLegend__unavailable}></div>
          {t('Please contact the hotel directly for assistance.')}
        </div>
        {!multiRooms && (
          <div>
            Prices shown in {currencyData.name} for 1-night stay. Rates
            displayed may be subject to a minumum length of stay. All rates are
            subject to availability.
          </div>
        )}

        {discretionaryCharge && (
          <div>{t('Including taxes and excluding 5% Service Charge')}</div>
        )}
      </div>

      <CalendarFeedback
        disabledPickedDateReason={disabledPickedDateReason}
        isSpecialCode={isSpecialCode}
      />
    </div>
  );
};

CalendarLegend.propTypes = {
  productCode: PropTypes.string,
  disabledPickedDateReason: PropTypes.string,
  isSpecialCode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default CalendarLegend;
