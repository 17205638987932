import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DayPickerRangeController } from 'react-dates-gte-react-17';
import 'react-dates-gte-react-17/initialize';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/icons/ArrowRight.svg';
import ViewLoader from '../../../../components/ViewLoader/ViewLoader';
import useLocalisedMoment from '../../../../hooks/useLocalisedMoment/useLocalisedMoment';
import { setIsCalendarOpen } from '../../../../redux/slices/availabilitySlice/availabilitySlice';
import { useCalendar } from '../../hooks';
import styles from './RangeDatePicker.module.css';
import CalendarLegend from './components/CalendarLegend/CalendarLegend';

const RangeDatePicker = ({
  onClose,
  focus,
  onChangeFocus,
  months,
  daySize,
  productCode,
}) => {
  const moment = useLocalisedMoment();
  const { getValues } = useFormContext();
  const dispatch = useDispatch();
  const [currentMonth, setCurrentMonth] = useState(
    moment(getValues('startDate')).format('YYYY-MM')
  );

  const calendarAvailability = useSelector(
    (state) => state.availability.calendar.products[productCode]
  );

  const {
    disabledPickedDateReason,
    renderDayContents,
    isDayBlocked,
    handleDateChange,
    handleCloseCalendar,
    specialCodeApplied,
    rateCodeApplied,
    agentId,
  } = useCalendar(productCode, currentMonth, focus);

  const previousMonth = moment(currentMonth)
    .clone()
    .subtract(1, 'month')
    .format('YYYY-MM');

  const isPreviousMonthInPast = moment(previousMonth).isBefore(
    moment().startOf('month')
  );

  useEffect(() => {
    dispatch(setIsCalendarOpen(true));
    return () => {
      dispatch(setIsCalendarOpen(false));
    };
  }, [dispatch]);

  return (
    <div className="position-relative w-100 h-100 d-inline-block">
      {!calendarAvailability || calendarAvailability.isLoading ? (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1006,
            width: '101.5%',
            height: '100%',
            bottom: 0,
            pointerEvents: 'none',
            background: 'white',
            opacity: 0.8,
          }}
        >
          <div style={{ marginBottom: 100 }}>
            <ViewLoader />
          </div>
        </div>
      ) : null}
      <DayPickerRangeController
        hideKeyboardShortcutsPanel
        keepOpenOnDateSelect={false}
        renderDayContents={renderDayContents}
        navPrev={
          !isPreviousMonthInPast && (
            <button
              // propagate the click event
              // use a button here so we are able to disable it
              disabled={!calendarAvailability || calendarAvailability.isLoading}
              aria-label="Go to previous month"
              style={{ left: '20px' }}
              className={styles.RangeDatePicker__nav_button}
            >
              <ArrowLeftIcon tabIndex={0} style={{ outline: 0 }} />
            </button>
          )
        }
        navNext={
          <button
            disabled={!calendarAvailability || calendarAvailability.isLoading}
            aria-label="Go to next month"
            className={styles.RangeDatePicker__nav_button}
            style={{ right: '20px' }}
          >
            <ArrowRightIcon tabIndex={0} style={{ outline: 0 }} />
          </button>
        }
        minDate={moment()}
        isDayBlocked={
          !calendarAvailability || calendarAvailability.isLoading
            ? () => true
            : (day) => isDayBlocked(moment(day))
        }
        onPrevMonthClick={(e) => {
          setCurrentMonth(e.format('YYYY-MM'));
        }}
        onNextMonthClick={(e) => setCurrentMonth(e.format('YYYY-MM'))}
        enableOutsideDays={false}
        transitionDuration={0}
        daySize={daySize}
        onOutsideClick={() => {
          handleCloseCalendar();
          if (onClose) onClose();
        }}
        numberOfMonths={months}
        isOutsideRange={(day) =>
          day.isSameOrBefore(moment().subtract(1, 'days'))
        }
        startDate={moment(getValues('startDate'))}
        endDate={getValues('endDate') ? moment(getValues('endDate')) : null}
        onDatesChange={handleDateChange}
        focusedInput={focus}
        onFocusChange={onChangeFocus}
        initialVisibleMonth={() => moment(getValues('startDate'))}
        renderCalendarInfo={() => (
          <CalendarLegend
            productCode={productCode}
            disabledPickedDateReason={disabledPickedDateReason}
            isSpecialCode={specialCodeApplied || agentId || rateCodeApplied}
          />
        )}
      />
    </div>
  );
};

RangeDatePicker.propTypes = {
  onClose: PropTypes.func,
  focus: PropTypes.string,
  onChangeFocus: PropTypes.func,
  months: PropTypes.number,
  daySize: PropTypes.number,
  productCode: PropTypes.string,
};

export default RangeDatePicker;
