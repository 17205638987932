import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PermanentModal from '../../../../components/PermanentModal/PermanentModal';
import useTranslate from '../../../../hooks/useTranslate/useTranslate';
import Payment from './Payment';
import styles from './PaymentSwitcher.module.css';

const PaymentSwitcher = forwardRef(
  (
    {
      onPaymentOptionChange,
      paymentProps,
      threedsModalOpen,
      onShowMessage,
      onCloseMessage,
      paymentOption,
    },
    ref
  ) => {
    const { t } = useTranslate();

    const payNowRef = useRef();
    const payAtHotelRef = useRef();
    const paymentMounted = useRef();

    const multiplePaymentOptionsEnabled = !!paymentProps.payNowData;

    // Add a key state to force rerender
    const [rerenderKey, setRerenderKey] = useState(0);

    useImperativeHandle(ref, () => ({
      submit: () =>
        multiplePaymentOptionsEnabled && paymentOption === 'payNow'
          ? payNowRef.current.submit()
          : payAtHotelRef.current.submit(),
      waitForThreeDSIframe: () =>
        multiplePaymentOptionsEnabled && paymentOption === 'payNow'
          ? payNowRef.current.waitForThreeDSIframe()
          : payAtHotelRef.current.waitForThreeDSIframe(),
    }));

    const handlePaymentMethods = (paymentOption) => {
      if (!multiplePaymentOptionsEnabled) return;
      if (paymentOption === 'payNow') {
        payAtHotelRef.current.close();
        payNowRef.current.open();
      } else {
        payNowRef.current.close();
        payAtHotelRef.current.open();
      }

      onPaymentOptionChange(paymentOption);
    };

    const handleHardReload = () => {
      // Increment the key to force a rerender of Payment components
      setRerenderKey((prevKey) => prevKey + 1);
    };

    useEffect(() => {
      if (!paymentMounted.current) {
        payNowRef.current?.close();
        paymentMounted.current = true;
      }
    }, []);

    return (
      <div>
        {multiplePaymentOptionsEnabled && (
          <div className={styles.PaymentSwitcher__paymentContainer}>
            <div className={styles.PaymentSwitcher__title}>PAY NOW</div>{' '}
            <div className={styles.PaymentSwitcher__infoText}>
              You will pay securely for your stay now.
            </div>
            <PermanentModal
              isModal={paymentOption === 'payNow' && threedsModalOpen}
              isHidden={paymentOption === 'payAtHotel'}
            >
              {threedsModalOpen && <h2>{t('Secure authentication')}</h2>}
              <Payment
                onShowMessage={onShowMessage}
                key={`pay-now-${rerenderKey}`}
                ref={payNowRef}
                {...paymentProps}
                forceShowingPaymentMethodCheckBox={
                  multiplePaymentOptionsEnabled
                }
                ariaLabelPrefix="payNow"
                onHardReload={handleHardReload}
              />
            </PermanentModal>
            {paymentOption === 'payAtHotel' && (
              <button
                className={styles.PaymentSwitcher__selectPaymentOptionButton}
                onClick={() =>
                  paymentOption !== 'payNow' && handlePaymentMethods('payNow')
                }
              >
                Select Pay Now
              </button>
            )}
          </div>
        )}

        <div
          className={
            multiplePaymentOptionsEnabled
              ? styles.PaymentSwitcher__paymentContainer
              : null
          }
        >
          {multiplePaymentOptionsEnabled && (
            <>
              <div className={styles.PaymentSwitcher__title}>
                PAY AT THE HOTEL (when you check-in)
              </div>
              <div className={styles.PaymentSwitcher__infoText}>
                Your credit card will not be charged – we only need it to
                guarantee your booking. You will make the payment yourself when
                you check-in.
              </div>
            </>
          )}
          <PermanentModal
            isModal={paymentOption === 'payAtHotel' && threedsModalOpen}
            isHidden={paymentOption === 'payNow'}
          >
            {threedsModalOpen && <h2>{t('Secure authentication')}</h2>}

            <Payment
              key={`pay-at-hotel-${rerenderKey}`}
              ref={payAtHotelRef}
              {...paymentProps}
              onShowMessage={onShowMessage}
              onCloseMessage={onCloseMessage}
              payNowData={null}
              forceShowingPaymentMethodCheckBox={multiplePaymentOptionsEnabled}
              ariaLabelPrefix="payAtHotel"
              onHardReload={handleHardReload}
            />
          </PermanentModal>
          {paymentOption === 'payNow' && (
            <button
              className={styles.PaymentSwitcher__selectPaymentOptionButton}
              onClick={() =>
                paymentOption !== 'payAtHotel' &&
                handlePaymentMethods('payAtHotel')
              }
            >
              Select Pay at Hotel
            </button>
          )}
        </div>
      </div>
    );
  }
);

PaymentSwitcher.propTypes = {
  onPaymentOptionChange: PropTypes.func.isRequired,
  paymentProps: PropTypes.object.isRequired,
  onShowMessage: PropTypes.func,
  onCloseMessage: PropTypes.func.isRequired,
  threedsModalOpen: PropTypes.bool,
  paymentOption: PropTypes.string.isRequired,
};

export default PaymentSwitcher;
