import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchCalendarAvailability } from '../../redux/slices/availabilitySlice/availabilitySlice';
import getNextAvailabileDates from '../../views/SelectRoom/helpers/get-next-available-dates';

const useFetchBookingLink = ({
  startDate,
  dispatch,
  moment,
  axios,
  countryCode,
  hotel,
}) => {
  const [bookingData, setBookingData] = useState({});
  const bookingBlobEnabled = hotel?.bookingBlob;
  const calAvailability = useSelector(
    (state) => state.availability.calendar.products
  );

  useEffect(() => {
    if (!bookingBlobEnabled) return;

    const fetchAvailability = async (productCode) => {
      try {
        let result = await dispatch(
          fetchCalendarAvailability(
            productCode,
            moment(startDate).format('YYYY-MM'),
            { numAdults: [2], children: [] },
            moment,
            axios,
            countryCode
          )
        );

        if (!result) {
          result = calAvailability?.[productCode]?.list;
        }

        const { startDate: sd, endDate: ed } = getNextAvailabileDates(
          result || [],
          moment
        );
        if (sd && ed) {
          const res = await axios.get('/room-availability', {
            params: {
              startDate: sd,
              endDate: ed,
              productCode,
              numAdults: 2,
              children: [],
              countryCode,
            },
          });

          setBookingData((prev) => ({
            ...prev,
            [productCode]: res?.data[productCode],
          }));
        }
      } catch (error) {
        console.error(error);
      }
    };

    hotel.nearbyProducts?.forEach((code) => fetchAvailability(code));
  }, [
    axios,
    hotel,
    countryCode,
    dispatch,
    moment,
    startDate,
    bookingBlobEnabled,
    calAvailability,
  ]);

  return bookingData;
};

export default useFetchBookingLink;
