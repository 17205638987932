import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BookingLinkProductRoom from './BookingLinkProductRoom';

const BookingLink = ({ bookingData }) => {
  const media = useSelector((state) => state.media.mediaUrls);
  const products = Object.keys(bookingData);

  if (!products?.length) return null;

  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="text-center mb-5">
        <h4>Further accommodation{products.length > 1 && 's'} nearby</h4>
      </div>
      {products.map((product) => (
        <BookingLinkProductRoom
          key={product}
          product={product}
          media={media}
          bookingData={bookingData}
        />
      ))}
    </div>
  );
};

BookingLink.propTypes = {
  bookingData: PropTypes.object.isRequired,
};

export default BookingLink;
